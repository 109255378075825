import { EVENTS, EventType } from "../config/eventsConfig";

import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { capitalize } from "../App";

interface EventSelectorProps {
  setEvent: (event: EventType) => void;
}

export function EventSelector(props: EventSelectorProps) {
  const xs = useMediaQuery(useTheme().breakpoints.down("sm"));
  const { setEvent } = props;
  const events = EVENTS;

  return (
    <Box
      display={"flex"}
      minHeight={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      bgcolor={"whitesmoke"}
    >
      <Box margin={2}>
        <Typography variant={xs ? "h5" : "h4"} mt={2} mb={4}>
          Catalogue des événements de JTM Events
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Grid container spacing={2} maxWidth={"1200px"}>
            {events.map((e, index) => {
              return (
                <Grid key={`selector-${index}`} item xs={12} sm={6} md={4}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="140"
                      image={`img/${e.img}`}
                      alt={e.title}
                    />
                    <CardContent sx={{ height: "285px", overflow: "auto" }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {capitalize(e.title)}
                      </Typography>
                      {e.description && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          textAlign={"left"}
                        >
                          {e.description}
                        </Typography>
                      )}
                    </CardContent>
                    <CardActions>
                      {/* <Button size="small">Share</Button> */}
                      <Button size="small" onClick={() => setEvent(e)}>
                        Sélectionner cet événement
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
