export async function sendMail(text: string, html?: string) {
  let myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Basic YXBpOjAyMWIxZmVkODhkMWU4NDRiZjQzZmI2N2FhYjE5Nzc5LWRlNzA2MmM2LTQ3MjgwYzY3"
  );

  let formdata = new FormData();
  formdata.append(
    "from",
    "mailgun@sandbox2fbf25af71494d03b46a723bbca67d04.mailgun.org"
  );
  formdata.append(
    "to",
    "stuff@david-toussaint.de,jtm.events.management@gmail.com"
  );
  formdata.append("subject", "Proposal");

  formdata.append("text", text);
  if (html) formdata.append("html", html);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    // redirect: 'follow'
  };

  return await fetch(
    "https://api.mailgun.net/v3/sandbox2fbf25af71494d03b46a723bbca67d04.mailgun.org/messages",
    requestOptions
  );
  // .then(response => response.text())
  // .then(result => console.log(result))
  // .catch(error => console.log('error', error));
}
