import { Box } from "@mui/material";
import React from "react";
import { EventType, EventCategoryFields } from "../config/eventsConfig";
import CategoryCard from "./CategoryCard";
import RequestProposal from "./RequestProposal";

import ResultCard from "./ResultCard";

export interface EventCategories {
  invitations: EventCategoryFields;
  flowers: EventCategoryFields;
  location: EventCategoryFields;
  music: EventCategoryFields;
  food: EventCategoryFields;
  drinks: EventCategoryFields;
  shuttle: EventCategoryFields;
}
export interface Proposal {
  budget: number;
  // categories: EventCategoryFields[]
  eventType: EventType;

  // categories: EventCategories
}

interface WizzardProps {
  eventType: EventType;
  setEvent: (event: EventType | undefined) => void;
}

export function Wizzard(props: WizzardProps) {
  const { eventType, setEvent } = props;
  const event = eventType.eventCategories;
  let [step, setStep] = React.useState<number>(0);
  // let [step, setStep] = React.useState<number>(7)
  let [proposal, setProposal] = React.useState<Proposal>();

  const [eventCategories, setEventCategories] =
    React.useState<EventCategoryFields[]>(event);

  const [error, setError] = React.useState(false);

  const categoryKeys = Object.keys(eventCategories);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    console.log("handle");
    setEventCategories((prev) => {
      // const percent = prev[target.name as keyof EventCategories]["ranges"].find(r => r <= Number(target.value))
      const index = prev.findIndex((c) => c.title === target.name);
      const range = prev[index]["ranges"][Number(target.value) - 1] || 0;
      prev[index].priority = Number(target.value);
      prev[index].percent = range.max || 0;

      return prev;
    });

    setError(!error);
  };

  const handleSteps = (step: number) => {
    if (step === -1) {
      setEvent(undefined);
    } else {
      setStep(step);
    }
  };

  const requestProposal = (proposal: Proposal) => {
    setProposal({ ...proposal });
    setStep(categoryKeys.length + 1);
  };

  return (
    <Box className="App">
      {step > -1 && step < categoryKeys.length && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            minHeight: "100vh",
            position: "absolute",
            top: "0px",
            left: "0px",
            overflow: "auto",
            background: `url(img/${eventType.img}) no-repeat center center fixed`,
            backgroundSize: "cover",
          }}
        >
          <CategoryCard
            setStep={handleSteps}
            handleRadioChange={handleRadioChange}
            eventType={eventType}
            eventCategory={eventCategories[step]}
            index={step}
          />
        </Box>
      )}

      {step === categoryKeys.length && (
        <ResultCard requestProposal={requestProposal} eventType={eventType} />
      )}
      {step > categoryKeys.length && <RequestProposal proposal={proposal!} />}
    </Box>
  );
}
