import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  CardMedia,
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import { red } from "@mui/material/colors";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import exp from "constants";
import * as React from "react";
import { capitalize } from "../App";
import { EventType, EventCategoryFields } from "../config/eventsConfig";
import { EventCategories } from "./Wizzard";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface CardArgs {
  index: number;
  eventCategory: EventCategoryFields;
  eventType: EventType;
  setStep: (step: number) => void;
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function CategoryCard(args: CardArgs) {
  const { eventCategory, handleRadioChange, index, setStep, eventType } = args;
  let step = index;
  const [expanded, setExpanded] = React.useState(false);

  const [error, setError] = React.useState(false);
  const xs = useMediaQuery(useTheme().breakpoints.down("sm"));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box margin={1} sx={{ width: "500px", maxWidth: "100%" }}>
      <Card key={`category-card-${index}`} sx={{ m: 1 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {eventCategory.title.substring(0, 1).toUpperCase()}
            </Avatar>
          }
          action={
            eventCategory.infoParagraphs &&
            eventCategory.infoParagraphs?.length > 0 && (
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <>
                  {expanded && <ExpandMoreIcon />}
                  {!expanded && <InfoIcon />}
                </>
              </ExpandMore>
            )

            // <IconButton aria-label="settings">
            //     <MoreVertIcon />
            // </IconButton>
          }
          title={
            <Typography sx={{ fontSize: "40px" }}>
              {capitalize(eventCategory.title)}
            </Typography>
          }
          // subheader="September 14, 2016"
        />
        <CardMedia
          component="img"
          height="194"
          image={`img/${eventCategory.img || eventType.img}`}
          alt="Paella dish"
        />
        <CardContent key="primary-content">
          <Typography variant="body2" color="text.secondary" textAlign={"left"}>
            {eventCategory.description}
          </Typography>
        </CardContent>

        <CardActions>
          <Box display={"flex"} width={"100%"} flexDirection={"column"}>
            <Typography variant="overline" textAlign={"center"}>
              Importance
            </Typography>
            <Box>
              <RadioGroup
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                value={eventCategory.priority}
                name={eventCategory.title}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  key={"prio-0"}
                  labelPlacement="top"
                  value="0"
                  control={<Radio />}
                  label={xs ? 0 : "Pas important"}
                />
                <FormControlLabel
                  key={"prio-1"}
                  labelPlacement="top"
                  value="1"
                  control={<Radio />}
                  label={xs ? 1 : "Basse"}
                />
                <FormControlLabel
                  key={"prio-2"}
                  labelPlacement="top"
                  value="2"
                  control={<Radio />}
                  label={xs ? 2 : "Moyenne"}
                />
                <FormControlLabel
                  key={"prio-3"}
                  labelPlacement="top"
                  value="3"
                  control={<Radio />}
                  label={xs ? 3 : "Haute"}
                />
              </RadioGroup>
            </Box>
            {/* <Button aria-label="not">
                        no need
                    </Button>
                    <Button aria-label="low">
                        Low
                    </Button>
                    <Button aria-label="med">
                        Medium
                    </Button>
                    <Button aria-label="high">
                        High
                    </Button> */}
          </Box>
          {eventCategory.infoParagraphs &&
            eventCategory.infoParagraphs?.length > 0 &&
            false && (
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <>
                  {expanded && <ExpandMoreIcon />}
                  {!expanded && <InfoIcon />}
                </>
              </ExpandMore>
            )}
        </CardActions>
        {/* <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions> */}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent key="info-content">
            <Typography variant="h6" gutterBottom textAlign={"left"}>
              Info:
            </Typography>
            {eventCategory.infoParagraphs &&
              eventCategory.infoParagraphs.map((p, i) => {
                return (
                  <>
                    {p.title && (
                      <Typography
                        key={`title-i`}
                        component="p"
                        variant="overline"
                        textAlign={"left"}
                      >
                        {p.title}
                      </Typography>
                    )}
                    <Typography key={`text-i`} paragraph textAlign={"left"}>
                      {p.text}
                    </Typography>
                  </>
                );
              })}
          </CardContent>
        </Collapse>
      </Card>
      <Box
        maxWidth={"500px"}
        width={"100%"}
        marginTop={2}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Button
          key="prev-button"
          color="secondary"
          variant="outlined"
          sx={{ backgroundColor: "white" }}
          onClick={() => setStep(--step)}
        >
          {step === 0 && <Typography>Annuler</Typography>}
          {step > 0 && <Typography>Retour</Typography>}
        </Button>

        <Button
          key="next-button"
          color="primary"
          variant="contained"
          onClick={() => setStep(++step)}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  );
}
