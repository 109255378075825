import React from "react";
import NumberFormat, { InputAttributes } from "react-number-format";

interface Range {
  min: number;
  max: number;
}

interface InfoParagraph {
  title?: string;
  text: string;
}

export interface EventCategoryFields {
  focusPercent?: boolean;

  title: string;
  description?: string;
  infoParagraphs?: InfoParagraph[];
  ranges: Range[];
  percent: number;
  priority: number;
  value?: number;
  edit?: boolean;
  expanded?: boolean;
  img?: string;
}

const wedding: EventCategoryFields[] = [
  {
    title: "Faire-part",
    description:
      "Entre la création artistique et le savoir-faire, le faire-part est devenu plus qu’un simple billet d’invitation. Il véhicule le ton que vous désirez pour votre mariage, détermine le style de la cérémonie et transmet le message auprès des différentes personnes dont vous aimeriez l’assistance",
    infoParagraphs: [
      {
        text: "Pour votre mariage, vous avez la possibilité d’opter pour un faire-part personnalisé et unique à travers",
      },
      {
        title: "Le faire-part traditionnel",
        text: "L’idée la plus classique, bien sollicitée, il reprend les codes les plus anciens de la réception. Vous pouvez faire parvenir un carton ou un document électronique (carré, rectangulaire, géométrique…) à vos invités en mettant en valeur votre texte de faire-part, le tout dans l’ambiance que vous choisissez.",
      },
      {
        title: "Le faire-part à thème",
        text: "De plus en plus séduisant, il reprend le thème de votre mariage à base d’un carton ou d’un document électronique exceptionnellement conçu selon votre thématique. Il bascule entre le faire-part glamour, champêtre, rétro avec un goût prononcé entre la plage, le cinéma, le cirque, le voyage et plus encore.",
      },
      {
        title: "Le faire-part artistique",
        text: "Mettant en avant l’art, l’esthétique, la création manuelle, il donne une touche artistique à votre mariage",
      },
      {
        title: "Le faire-part schématique",
        text: "Plus moderne, il remplace le texte d’invitation classique par la proposition schématique de la réalisation chronologique et illustrée des différents temps forts de votre mariage.",
      },
      {
        title: "Le faire-part éco-responsables",
        text: "Il illustre votre engagement vis-à-vis de l’environnement",
      },
      {
        title: "Le faire-part humoristique",
        text: "Il décrit l’ambiance festive de votre mariage avec un ton d’humour et de convivialité",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 11,
    priority: 3,
    img: "invitations.jpg",
  },
  {
    title: "Fleurs",
    description:
      "Elles embellissent l’espace et subliment la décoration de votre événement. Avec leur charme naturel, les compositions florales apportent un plus à l’allure globale et scénographique au mariage. ",
    infoParagraphs: [
      {
        text: "Entre le bouquet de la mariée et la décoration de l’espace, vous pouvez choisir",
      },
      {
        title: "Les fleurs artificielles",
        text: "Elles apportent une touche toute particulière, colorée et variée à votre cérémonie ; faciles à entretenir, durables et économiques permettant de couvrir un plus grand espace.",
      },
      {
        title: "Les fleurs naturelles",
        text: "Elles accompagnent tous nos événements et on ne saurait s’en passer ; elles donnent une aire fraîche à la décoration et nous rapprochent de la nature.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 8,
    priority: 2,
    img: "flowers.jpg",
  },
  {
    title: "localisation",
    description:
      "Le lieu est un élément considérable dans l’organisation de votre mariage selon votre concept, votre thème, l’allure et le message qui doivent accompagner votre événement",
    infoParagraphs: [
      { text: "Où comptez-vous donner corps à votre célébration de mariage?" },
      {
        title: "Espace ouvert",
        text: "Le lieu ouvert rend concret le désir d’un mariage en plein air et réalise l’idée originale de votre union qui peut se célébrer dans un parc, un patio, une terrasse, une péniche, une plage, au bord d’un lac (rivière, fleuve, cours d’eau…), une aire de jeu (golf, jardin publique…), un camping, une ferme… l’idée d’un espace ouvert nous sort de la monotonie des enclos, nous permet de donner une touche particulière à notre événement et à se démarquer du déjà-vu le tout dans une ambiance et un charme uniques.",
      },
      {
        title: "Espace fermé",
        text: "Le lieu fermé est l’idéal d’espace pour tout type de cérémonies ; il vous met à l’abri de tout et vous donne le contrôle nécessaire sur le déroulement de votre mariage. Vous pouvez opter pour une salle de fête, un restaurant, une maison d’hôtes, une boîte de nuit, pourquoi pas chez soi pour une ambiance familiale",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 13,
    priority: 3,
    img: "location.jpg",
  },
  {
    title: "Musique",
    description:
      "Avec Emmanuel Kant, nous reconnaissons que « la musique est la langue des émotions » donnant un air divertissant susceptible de marquer les esprits de vos invités. Sa place peut se décliner sous différents angles durant votre mariage : comme le centre du spectacle, comme l’accompagnement pendant le déroulement de votre mariage, comme la régulation entre différentes activités ou les différentes parties de votre mariage, comme la parfaite illustration du message à transmettre à votre mariage. Au-delà de toute considération, une chose est sure, la musique brise la monotonie de votre cérémonie, d’où la nécessiter de bien choisir son style selon le thème de votre mariage, les invités et l’orientation des activités tout au long de la cérémonie",
    infoParagraphs: [
      { text: "Ainsi, vous avez le choix entre :" },
      {
        title: "Un DJ",
        text: "Devenu aujourd’hui un allié incontournable de l’événementiel, il est la personne chargée du choix des morceaux et de l’animation de votre mariage.",
      },
      {
        title: "Une musique en live",
        text: "Elle vous met dans le contexte des prestations musicales par des groupes, des chantres, des artistes et confère à votre mariage une atmosphère authentique et originale.",
      },
      {
        title: "Une animation événementielle musicale",
        text: "Elle offre une présentation musicale variée pendant votre mariage grâce aux concerts, aux showcases…",
      },
      {
        title: "Un Bling test et un Karaoké",
        text: "Ils font de vos invités, des participants actifs à l’événement et les plongent dans leurs vieux souvenirs, leurs amours passés avec cette sensation de revivre profitant à fond de la cérémonie.",
      },
      {
        title: "Une musique classique ou traditionnelle",
        text: "À vous de voir compte tenu de vos invités, de l’ambiance et surtout du type de mariage.",
      },
      {
        title: "Un style propre de musique",
        text: "Elle vous permet de donner une marque propre et écrire votre propre histoire.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 8,
    priority: 2,
    img: "music.jpg",
  },
  {
    title: "Repas",
    description:
      "La nourriture trouve sa place pendant votre mariage ; de ce fait, il est important vous de tenir compte des différentes tendances en matière de restauration non pas seulement pour nourrir les invités mais aussi créer un moment idéal pour rassembler, connecter et tisser des liens entre eux. Cela dépend de la qualité du service offert.",
    infoParagraphs: [
      { text: "Alors pour votre mariage, vous désirez:" },
      {
        title: "Une cuisine locale",
        text: "En fonction de vos invités, surtout venant de la diaspora, une découverte des plats locaux leur permet de savouver la culture camerounaise sous une autre facette",
      },
      {
        title: "Une cuisine occidentale",
        text: "Découvrons le monde, découvrons d´autres cultures culinaires en proposant aux invités par exemple de bons plats italiens ou espagnols",
      },
      {
        title: "Le mix local et oriental",
        text: "Vous associez la cuisine locate et la cuisine occidentale afin de satisfaire le maximum de vos invités",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 3,
    img: "food.jpg",
  },
  {
    title: "Boissons",
    description:
      "Le breuvage accompagne la nourriture et constitue en soi un élément d’appréciation de votre cérémonie",
    infoParagraphs: [
      {
        text: "Ainsi, déterminez ce que vous mettez à la disposition de vos invités avec un service de bar impeccable :",
      },
      {
        title: "L’eau fraiche",
        text: "L´eau c´est la vie ; c´est une obligation pour toute table",
      },
      {
        title: "Les boissons non alcoolisées",
        text: "En fonction de vos invités vous choisissez les variétés de boissons sans alcool à leur faire déguster. Vous respectez par ricochet les religions où la consommation d´alcool est interdite",
      },
      {
        title: "Les boissons alcoolisées",
        text: "Entre Whisky, vins rouges, vins blancs, vins mousseux et champagnes, nous étudions avec vous la composition de vos invités afin de faire un plan de boissons alcoolisés",
      },
      {
        title: "Les cocktails",
        text: "Pour une cérémonie avec bar ou à l´aire libre en journée, des cocktails sont des raffraichissants à proposer dans ce cadre à vos invités",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 3,
    img: "drinks.jpg",
  },
  {
    title: "Transport",
    description:
      "Le transport comme moyen de déplacement vous permet d’assurer vos mouvements et les trafics pendant votre mariage. Il faut prévoir la voiture des mariés, la voiture des témoins et celle des filles et des garçons d’honneur ; prévoir le cortège des invités et le reste. Le transport facilite ainsi la mobilité et la fluidité des activités tout au long du mariage.",
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 6,
    priority: 2,
    img: "shuttle.jpg",
  },
  {
    title: "Décoration",
    description:
      "La décoration du lieu de votre événement est un élément essentiel de la réussite et surtout ce qui reste dans les mémoires de vos invités. Choisissez vos couleurs, vos thèmes et laissez les professionnels transformez votre moment comme vous le rêvez",
    infoParagraphs: [
      { text: "Pensez aux éléments que vous voulez décorer :" },
      {
        title: "Décoration des chaises",
        text: "Voulez des chaises nues (par exemple Napoleon?), des chaises habillées en housses blanches ou autres couleurs?",
      },
      {
        title: "Décoration des tables",
        text: "Voulez vous des vases? des chemins de tables, de quoi doit être composé vos couverts?",
      },
      {
        title: "Fond de scène",
        text: "Voulez vous du morderne? du classique? du traditionnel? Quel thème est votre favori? Quelles sont vos couleurs?",
      },
      {
        title: "Coin photo",
        text: "Besoin de tapis rouge? Besoin de jeux de lumières? Besoin de ballons?",
      },
      {
        title: "Autres",
        text: "Voulez-vous que vos invités posent à leurs arrivée? Avec votre photo en background?",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 13,
    priority: 3,
    img: "decoration.jpg",
  },
  {
    title: "Photo/Video/Drone",
    description:
      "Vous êtes naturellement interessés à garder des souvenirs en laissant nos professionnels faire des photos et vidéos.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Photos",
        text: "Nos photographes se chargent de prendre les photos durant toutes les étapes de votre cérémonie et à la fin de vous produire un très bel album photo",
      },
      {
        title: "Vidéos",
        text: "Tout comme les photos, nos vidéates ne ratent aucun mouvement de votre cérémonie, vous permettant de bien les revivre plus tard",
      },
      {
        title: "Drones",
        text: "Dans les airs nous sommes aussi présents en vous réalisant des vues magnifiques",
      },
      {
        title: "Projecteurs",
        text: "Grâce à nos projecteurs et l´équipement nécessaire, des projections des videos, images et autres sont possibles durant l´événement",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 3,
    img: "photography.jpg",
  },
  {
    title: "Services spéciaux",
    description:
      "Des services spéciaux comme la mise à disposition des agents de sécurité, des hôtesses et hôtes sont également des éléments important qui contribent à la réussite de votre événement",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Sécurité",
        text: "La sécurité des biens et personnes est très important afin que vos ami(e)s et connaissance puissent bien se sentir durant toute votre cérémonie",
      },
      {
        title: "Hôtesses et hôtes",
        text: "Il n´est pas toujours nécessaire ce compter sur la famille ou les amis pour le service lors de votre cérémonie. Des personnes formées pour celà sauront rendrent vos invités très à l´aise.",
      },
      {
        title: "Master of Ceremony",
        text: "Un bon MC assure l´ambiance tout au long de la cérémonie, il y met de la structure, c´est pourquoi ce service est essentiel pour un bon déroulement des activités prévues",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 1,
    priority: 1,
    img: "specialservice.jpg",
  },
  {
    title: "Logistique",
    description:
      "Parfois nous avons un lieu d´événement, mais pas de chaises ni de tables et encore moins les couverts ou marmites chauffantes pour exposer les repas.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Chaises",
        text: "Plastiques, modernes ou VIP, nous avons differentes variantes à vous proposer",
      },
      {
        title: "Tables",
        text: "Carrées, rectangulaires, rondes ou ovales, en fonction de la dispotion de la salle on saura ce qui passe le mieux",
      },
      {
        title: "Marmites chauffantes",
        text: "Anciens modèles ou VIP, choisissez ce que vous voulez en fonction de la grandeur de votre cérémonie",
      },
      {
        title: "Tentes",
        text: "Sur differentes dimensions, standard ou VIP, nous mettons ce dont vous avez besoin à votre disposition",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 3,
    img: "logistic.jpg",
  },
];

const funeral: EventCategoryFields[] = [
  {
    title: "Faire-Part",
    description:
      "Entre la création artistique et le savoir-faire, le faire-part pour des obsèques est plus qu’un simple billet d’invitation. Il véhicule et porte des messages qui expriment votre douleur et sur quoi vous vous appuyez pour voir l´avenir avec espoir.",
    infoParagraphs: [
      {
        text: "Pour la cérémonie d´obsèques de votre bien aimé disparu, vous avez la possibilité d’opter pour un faire-part personnalisé et unique avec le choix entre :",
      },
      {
        title: "Le faire-part traditionnel",
        text: "L’idée la plus classique, bien sollicitée, il reprend les codes les plus anciens. Vous pouvez faire parvenir un carton ou un document électronique (carré, rectangulaire, géométrique…) à vos invités en mettant en valeur votre texte de faire-part, le tout tenant compte de la tristesse dans laquelle vous vous trouvez.",
      },
      {
        title: "Le faire-part à thème",
        text: "Il reprend le thème de la dimension que vous voulez mettre en valeur en souvenir de la personne disparue. Il fait à base d’un carton ou d’un document électronique exceptionnellement conçu selon l’esprit et le ton donné à la célébration des funérailles",
      },
      {
        title: "Le faire-part artistique",
        text: "Mettant en avant l’art, l’esthétique, la création manuelle, il donne une touche artistique aux invitations des obsèques.",
      },
      {
        title: "Le faire-part schématique",
        text: "Plus moderne, il remplace le texte d’invitation classique par la proposition schématique de la réalisation chronologique et illustrée des différents temps forts de la cérémonie des obsèques.",
      },
      {
        title: "Le faire-part éco-responsables",
        text: "Il illustre votre engagement vis-à-vis de l’environnement",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 3,
    img: "invitations.jpg",
  },
  {
    title: "Fleurs",
    description:
      "Il est bien vrai que les funérailles constituent un événement triste, douloureux et malheureux. Cependant, la présence des fleurs redonne un air joyeux et avec une ambiance qui vous permet de surpasser l’instant présent.",
    infoParagraphs: [
      {
        text: "Entre la couronne mortuaire, les gerbes de cimetières et la décoration, vous avez le choix entre :",
      },
      {
        title: "Les fleurs artificielles",
        text: "Elles apportent une touche toute particulière, colorée et variée à votre cérémonie ; faciles à entretenir, durables et économiques permettant de couvrir un plus grand espace.",
      },
      {
        title: "Les fleurs naturelles",
        text: "Elles accompagnent tous nos événements et on ne saurait s’en passer ; elles donnent une aire fraîche à la décoration et vous rapprochent de la nature.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 2,
    img: "flowers.jpg",
  },
  {
    title: "Localisation",
    description:
      "Le lieu est un élément considérable dans l’organisation des obsèques ; Plus souvent le lieu standard est la maison d´habitation du défunt dans la ville de résidence et son village natal. Néanmoins une délocalisation peut avoir lieu ou un aggrandissement de l´espace nécessaire. ",
    infoParagraphs: [
      {
        text: "Où comptez-vous rendre le dernier hommage à cet être cher qui vous a quitté :",
      },
      {
        title: "Domicile privé",
        text: "Le domicile du défunt en ville et en campagne est accessible et peut recevoir tous les invités attendus",
      },
      {
        title: "Extension du domicile privé",
        text: "Le domicile du défunt en ville et en campagne est accessible et mais ne peut recevoir tous les invités attendus, un aggrandissement de l´espace par des tentes et autres est nécessaire",
      },
      {
        title: "Délocalisation vers un espace ouvert",
        text: "Le lieu ouvert rend concret le désir de communier directement avec la nature, les esprits et tout le monde de l’au-delà. De ce fait, les obsèques peuvent se dérouler dans un parc, une terrasse, une avenue, une plage, au bord d’un lac (rivière, fleuve, cours d’eau…), une aire de jeu (jardin public…), un camping, une ferme… l’idée d’un espace ouvert vous sort de la monotonie des enclos.",
      },
      {
        title: "Délocalisation vers un espace fermé",
        text: "Le lieu fermé est l’idéal d’espace pour toute cérémonie ; il vous met à l’abri de tout et vous donne le contrôle nécessaire sur l’événement tout en conférant une ambiance privée. Vous pouvez opter pour une salle, une maison d’hôtes, pourquoi pas chez soi pour une ambiance familiale prononcée ou dans une église…",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 5,
    priority: 1,
    img: "location.jpg",
  },
  {
    title: "Musique",
    description:
      "Avec Emmanuel Kant, nous reconnaissons que « la musique est la langue des émotions », une véritable expression de cet événement avec le but de marquer les esprits. Sa place, tout au long de votre cérémonie des funérailles consiste à vous accompagner pendant le déroulement de l’événement tout en régulant les différentes activités et l’illustration de la vie de l’illustre disparu. Au-delà de toute considération, une chose est sure, la musique brise la monotonie de votre cérémonie, d’où la nécessité de bien choisir votre style en harmonie avec l’hommage à rendre.",
    infoParagraphs: [
      { text: "Vous pouvez opter pour :" },
      {
        title: "Une musique en live",
        text: "Elle vous plonge dans la prestation musicale directe grâce aux groupes, aux chantres, aux artistes tout en conférant à l’événement une atmosphère authentique.",
      },
      {
        title: "Une animation événementielle musicale",
        text: "pour une animation variée pendant votre cérémonie avec des concerts, des showcases…",
      },
      {
        title: "Un Karaoké",
        text: "Il permet aux participants d’exprimer leur souvenir de la vie passée avec le défunt.",
      },
      {
        title:
          "Une musique classique, une musique traditionnelle ou une musique religieuse",
        text: "À vous de voir compte tenu de l’ambiance que vous désirez maintenir pendant les obsèques.",
      },
      {
        title: "Un style propre de musique",
        text: "À composer selon les souvenirs de votre être cher pour donner une marque propre et offrir un vibrant hommage à la personne que vous pleurez.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 8,
    priority: 2,
    img: "music.jpg",
  },
  {
    title: "Repas",
    description:
      "La nourriture trouve de plus en plus sa place pendant les funérailles ; de ce fait, il devient de plus en plus important, pour vous, de tenir compte des différentes tendances en matière de restauration non pas seulement pour nourrir ceux qui vous assistent et vous soutiennent dans cette épreuve difficile mais aussi de créer un moment idéal qui vous rassemble, vous connecte et tisse des liens entre vous.",
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 3,
    img: "food.jpg",
  },
  {
    title: "Boissons",
    description:
      "Le breuvage accompagne la nourriture et constitue en soi un élément d’appréciation de votre cérémonie. Ainsi, déterminez ce que vous mettez à la disposition de vos invités avec un service de bar impeccable à la fin de votre cérémonie de funérailles.",
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 3,
    img: "drinks.jpg",
  },
  {
    title: "Transport",
    description:
      "Le transport comme moyen de déplacement vous permet d’assurer les mouvements et les trafics pendant cette cérémonie. Tenir compte de ce point vous facilite la mobilité et la fluidité des activités au courant de l’événement. Vous aurez besoin : d’un corbillard, d’un cortège pour le déplacement de tous ceux qui seront présents à ces derniers hommages.",
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 8,
    priority: 2,
    img: "shuttle.jpg",
  },
  {
    title: "Décoration",
    description:
      "La décoration du lieu de votre événement est un élément essentiel de la réussite et surtout ce qui reste dans les mémoires de vos invités. Choisissez vos couleurs, vos thèmes et laissez les professionnels transformez votre moment comme vous le rêvez",
    infoParagraphs: [
      { text: "Pensez aux éléments que vous voulez décorer :" },
      {
        title: "Décoration des chaises",
        text: "Voulez des chaises nues (par exemple Napoleon?), des chaises habillées en housses blanches ou autres couleurs?",
      },
      {
        title: "Décoration des tables",
        text: "Voulez vous des vases? des chemins de tables, de quoi doit être composé vos couverts?",
      },
      {
        title: "Fond de scène",
        text: "Voulez vous du morderne? du classique? du traditionnel? Quel thème est votre favori? Quelles sont vos couleurs?",
      },
      {
        title: "Salle du défunt",
        text: "Avec la famille decider du choix des couleurs, bougies et autres",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 13,
    priority: 3,
    img: "decoration.jpg",
  },
  {
    title: "Photo/Video/Drone",
    description:
      "Vous êtes naturellement interessés à garder des souvenirs en laissant nos professionnels faire des photos et vidéos.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Photos",
        text: "Nos photographes se chargent de prendre les photos durant toutes les étapes de votre cérémonie et à la fin de vous produire un très bel album photo",
      },
      {
        title: "Vidéos",
        text: "Tout comme les photos, nos vidéates ne ratent aucun mouvement de votre cérémonie, vous permettant de bien les revivre plus tard",
      },
      {
        title: "Drones",
        text: "Dans les airs nous sommes aussi présents en vous réalisant des vues magnifiques",
      },
      {
        title: "Projecteurs",
        text: "Grâce à nos projecteurs et l´équipement nécessaire, des projections des videos, images et autres sont possibles durant l´événement",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 3,
    img: "photography.jpg",
  },
  {
    title: "Services spéciaux",
    description:
      "Des services spéciaux comme la mise à disposition des agents de sécurité, des hôtesses et hôtes sont également des éléments important qui contribent à la réussite de votre événement",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Sécurité",
        text: "La sécurité des biens et personnes est très important afin que vos ami(e)s et connaissance puissent bien se sentir durant toute votre cérémonie",
      },
      {
        title: "Hôtesses et hôtes",
        text: "Il n´est pas toujours nécessaire ce compter sur la famille ou les amis pour le service lors de votre cérémonie. Des personnes formées pour celà sauront rendrent vos invités très à l´aise.",
      },
      {
        title: "Master of Ceremony",
        text: "Un bon MC même pour cérémonie triste saura trouver les bons mots pour calmer les douleurs",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 4,
    priority: 1,
    img: "specialservice.jpg",
  },
  {
    title: "Logistique",
    description:
      "Parfois nous avons un lieu d´événement, mais pas de chaises ni de tables et encore moins les couverts ou marmites chauffantes pour exposer les repas.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Chaises",
        text: "Plastiques, modernes ou VIP, nous avons differentes variantes à vous proposer",
      },
      {
        title: "Tables",
        text: "Carrées, rectangulaires, rondes ou ovales, en fonction de la dispotion de la salle on saura ce qui passe le mieux",
      },
      {
        title: "Marmites chauffantes",
        text: "Anciens modèles ou VIP, choisissez ce que vous voulez en fonction de la grandeur de votre cérémonie",
      },
      {
        title: "Tentes",
        text: "Standard ou VIP nous mettons à disposition ce dont vous avez besoin",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 12,
    priority: 3,
    img: "logistic.jpg",
  },
];

const birthday: EventCategoryFields[] = [
  {
    title: "Faire-part",
    description:
      "Entre la création artistique et le savoir-faire, le faire-part est devenu plus qu’un simple billet d’invitation. Il véhicule le ton de l’anniversaire, détermine le style et transmet le message auprès des différentes personnes dont vous aimeriez l’assistance.",
    infoParagraphs: [
      {
        text: "Pour votre anniversaire, vous avez la possibilité d’opter pour un faire-part personnalisé et unique avec le choix entre :",
      },
      {
        title: "Le faire-part traditionnel",
        text: "c’est l’idée la plus classique et bien sollicitée qui reprend les codes les plus anciens de la réception. Pour votre anniversaire, vous pouvez faire parvenir un carton ou un document électronique (carré, rectangulaire, géométrique…) mettant en valeur un texte de faire-part, le tout dans l’ambiance choisie de votre événement.",
      },
      {
        title: "Le faire-part à thème",
        text: "De plus en plus séduisant, il reprend le thème de votre anniversaire ; fait à base d’un carton ou d’un document électronique exceptionnellement conçu selon votre thématique. Il bascule entre le faire-part glamour, champêtre, rétro, la plage, le cinéma, le cirque, le voyage et plus encore.",
      },
      {
        title: "Le faire-part artistique",
        text: "Mettant en avant l’art, l’esthétique, la création manuelle, il donne une touche artistique à votre anniversaire.",
      },
      {
        title: "Le faire-part schématique",
        text: "Plus moderne, il remplace le texte d’invitation classique avec la proposition schématique de votre réalisation chronologique et illustrée des différents temps forts de votre anniversaire.",
      },
      {
        title: "Le faire-part éco-responsables",
        text: "Il donne ainsi une coloration responsable et engagée de votre événement",
      },
      {
        title: "Le faire-part humoristique",
        text: "Il décrit l’ambiance festive de votre anniversaire avec un ton d’humour et de convivialité…",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 12,
    priority: 3,
    img: "invitations.jpg",
  },
  {
    title: "localisation",
    description:
      "Le lieu est un élément considérable dans l’organisation de son anniversaire ; son choix dépend du concept, du thème, de l’allure et du message qui doivent accompagner cet événement.",
    infoParagraphs: [
      { text: "Où comptez-vous faire revivre ce souvenir :" },
      {
        title: "Espace ouvert",
        text: "Le lieu ouvert rend concret le désir d’un anniversaire en plein air et réalise l’idée originale d’une parfaite communion avec la nature. Il peut se célébrer dans un parc, un patio, une terrasse, une péniche, une plage, au bord d’un lac (rivière, fleuve, cours d’eau…), une aire de jeu (golf, jardin publique…), un camping, une ferme… l’idée d’un espace ouvert nous sort de la monotonie des enclos, vous permet de donner une touche particulière à votre anniversaire et à vous démarquer du déjà-vu, le tout dans une ambiance et un charme uniques.",
      },
      {
        title: "Espace fermé",
        text: "Le lieu fermé est l’idéal d’espace pour toute cérémonie ; il vous met à l’abri de tout et vous donne le contrôle nécessaire sur l’événement. Vous pouvez opter pour une salle de fête, un restaurant, une maison d’hôtes, une boîte de nuit, pourquoi pas chez soi pour une ambiance familiale prononcée…",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 14,
    priority: 3,
    img: "location.jpg",
  },
  {
    title: "Musique",
    description:
      "Avec Emmanuel Kant, nous reconnaissons que « la musique est la langue des émotions » donnant un air divertissant susceptible de marquer les esprits. Sa place, tout au long de votre anniversaire, se décliner sous différents angles : comme centre du spectacle, comme accompagnement pendant le déroulement de l’événement, comme régulation entre différentes activités de l’anniversaire ou comme la parfaite illustration du message que vous désirez transmettre. Au-delà de toute considération, une chose est sure, la musique brise la monotonie de votre cérémonie, d’où la nécessiter de bien choisir votre style en harmonie avec le thème de votre anniversaire, de vos invités et de l’orientation des différentes activités pendant la cérémonie.",
    infoParagraphs: [
      { text: "Ainsi, vous avez le choix entre :" },
      {
        title: "Un DJ",
        text: "Devenu aujourd’hui un allié incontournable de l’événementiel, il est la personne chargée du choix des morceaux et de l’animation de votre mariage.",
      },
      {
        title: "Une musique en live",
        text: "Elle vous met dans le contexte des prestations musicales par des groupes, des chantres, des artistes et confère à votre mariage une atmosphère authentique et originale.",
      },
      {
        title: "Une animation événementielle musicale",
        text: "Elle offre une présentation musicale variée pendant votre mariage grâce aux concerts, aux showcases…",
      },
      {
        title: "Un Bling test et un Karaoké",
        text: "Ils font de vos invités, des participants actifs à l’événement et les plongent dans leurs vieux souvenirs, leurs amours passés avec cette sensation de revivre profitant à fond de la cérémonie.",
      },
      {
        title: "Une musique classique ou traditionnelle",
        text: "À vous de voir compte tenu de vos invités, de l’ambiance et surtout de l’orientation de votre anniversaire.",
      },
      {
        title: "Un style propre de musique",
        text: "Elle vous permet de donner une marque propre et écrire votre propre histoire.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 9,
    priority: 2,
    img: "music.jpg",
  },
  {
    title: "Repas",
    description:
      "La nourriture trouve sa place pendant votre anniversaire ; de ce fait, il devient de plus en plus important, pour vous, de tenir compte des tendances en matière de restauration non pas seulement pour nourrir vos invités mais aussi créer un moment idéal qui rassemble, connecte et tisse des liens entre eux. Cela dépend de la qualité du service offert durant cet événement.",
    infoParagraphs: [
      { text: "Alors, désirez-vous :" },
      {
        title: "Une cuisine locale",
        text: "En fonction de vos invités, surtout venant de la diaspora, une découverte des plats locaux leur permet de savouver la culture camerounaise sous une autre facette",
      },
      {
        title: "Une cuisine occidentale",
        text: "Découvrons le monde, découvrons d´autres cultures culinaires en proposant aux invités par exemple de bons plats italiens ou espagnols",
      },
      {
        title: "Le mix local et oriental",
        text: "Vous associez la cuisine locate et la cuisine occidentale afin de satisfaire le maximum de vos invités",
      },
      {
        title: "Menu enfants",
        text: "Pour un anniversaire d´enfants, le menu tient compte leurs préférences en matière de goût et quantités",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 11,
    priority: 3,
    img: "food.jpg",
  },
  {
    title: "Boissons",
    description:
      "Le breuvage accompagne la nourriture et constitue en soi un élément d’appréciation de votre cérémonie.",
    infoParagraphs: [
      {
        text: "Ainsi, déterminez ce que vous mettez à la disposition de vos invités avec un service de bar impeccable :",
      },
      {
        title: "L’eau fraiche",
        text: "L´eau c´est la vie ; c´est une obligation pour toute cérémonie",
      },
      {
        title: "Les boissons non alcoolisées",
        text: "En fonction de vos invités vous choisissez les variétés de boissons sans alcool à leur faire déguster. Vous respectez par ricochet les religions où la consommation d´alcool est interdite. Pour un anniversaire d´enfant, des jus connus par ceux-ci seront les préférences",
      },
      {
        title: "Les boissons alcoolisées",
        text: "Entre Whisky, vins rouges, vins blancs, vins mousseux et champagnes, nous étudions avec vous la composition de vos invités afin de faire un plan de boissons alcoolisés",
      },
      {
        title: "Les cocktails",
        text: "Pour une cérémonie avec bar ou à l´aire libre en journée, des cocktails sont des raffraichissants à proposer dans ce cadre à vos invités",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 11,
    priority: 3,
    img: "drinks.jpg",
  },
  {
    title: "Transport",
    description:
      "Le transport comme moyen de déplacement vous permet d’assurer les mouvements et les trafics pendant votre anniversaire. Tenir compte de ce point vous facilite la mobilité et la fluidité des activités au courant de l’événement.",
    ranges: [
      { min: 0, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 4,
    priority: 1,
    img: "shuttle.jpg",
  },
  {
    title: "Décoration",
    description:
      "La décoration du lieu de votre événement est un élément essentiel de la réussite et surtout ce qui reste dans les mémoires de vos invités. Choisissez vos couleurs, vos thèmes et laissez les professionnels transformez votre moment comme vous le rêvez",
    infoParagraphs: [
      { text: "Pensez aux éléments que vous voulez décorer :" },
      {
        title: "Décoration des chaises",
        text: "Voulez des chaises nues (par exemple Napoleon?), des chaises habillées en housses blanches ou autres couleurs?",
      },
      {
        title: "Décoration des tables",
        text: "Voulez vous des vases? des chemins de tables, de quoi doit être composé vos couverts?",
      },
      {
        title: "Fond de scène",
        text: "Voulez vous du morderne? du classique? du traditionnel? Quel thème est votre favori? Quelles sont vos couleurs?",
      },
      {
        title: "Coin photo",
        text: "Besoin de tapis rouge? Besoin de jeux de lumières? Besoin de ballons?",
      },
      {
        title: "Autres",
        text: "Voulez-vous que vos invités posent à leurs arrivée? Avec votre photo en background?",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 14,
    priority: 3,
    img: "decoration.jpg",
  },
  {
    title: "Photo/Video/Drone",
    description:
      "Vous êtes naturellement interessés à garder des souvenirs en laissant nos professionnels faire des photos et vidéos.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Photos",
        text: "Nos photographes se chargent de prendre les photos durant toutes les étapes de votre cérémonie et à la fin de vous produire un très bel album photo",
      },
      {
        title: "Vidéos",
        text: "Tout comme les photos, nos vidéates ne ratent aucun mouvement de votre cérémonie, vous permettant de bien les revivre plus tard",
      },
      {
        title: "Drones",
        text: "Dans les airs nous sommes aussi présents en vous réalisant des vues magnifiques",
      },
      {
        title: "Projecteurs",
        text: "Grâce à nos projecteurs et l´équipement nécessaire, des projections des videos, images et autres sont possibles durant l´événement",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 12,
    priority: 3,
    img: "photography.jpg",
  },
  {
    title: "Services spéciaux",
    description:
      "Des services spéciaux comme la mise à disposition des agents de sécurité, des hôtesses et hôtes sont également des éléments important qui contribent à la réussite de votre événement",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Sécurité",
        text: "La sécurité des biens et personnes est très important afin que vos ami(e)s et connaissance puissent bien se sentir durant toute votre cérémonie",
      },
      {
        title: "Hôtesses et hôtes",
        text: "Il n´est pas toujours nécessaire ce compter sur la famille ou les amis pour le service lors de votre cérémonie. Des personnes formées pour celà sauront rendrent vos invités très à l´aise.",
      },
      {
        title: "Master of Ceremony",
        text: "Un bon MC assure l´ambiance tout au long de la cérémonie, il y met de la structure, c´est pourquoi ce service est essentiel pour un bon déroulement des activités prévues",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 1,
    priority: 1,
    img: "specialservice.jpg",
  },
  {
    title: "Logistique",
    description:
      "Parfois nous avons un lieu d´événement, mais pas de chaises ni de tables et encore moins les couverts ou marmites chauffantes pour exposer les repas.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Chaises",
        text: "Plastiques, modernes ou VIP, nous avons differentes variantes à vous proposer",
      },
      {
        title: "Tables",
        text: "Carrées, rectangulaires, rondes ou ovales, en fonction de la dispotion de la salle on saura ce qui passe le mieux",
      },
      {
        title: "Marmites chauffantes",
        text: "Anciens modèles ou VIP, choisissez ce que vous voulez en fonction de la grandeur de votre cérémonie",
      },
      {
        title: "Tentes",
        text: "Sur differentes dimensions, standard ou VIP, nous mettons ce dont vous avez besoin à votre disposition",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 12,
    priority: 3,
    img: "logistic.jpg",
  },
];

const party: EventCategoryFields[] = [
  {
    title: "Faire-part",
    description:
      "Entre la création artistique et le savoir-faire, le faire-part est devenu plus qu’un simple billet d’invitation. Il véhicule le ton de votre activité festive auprès des personnes que vous désirez conviées à cet événement.",
    infoParagraphs: [
      {
        text: "Vous pouvez donc opter pour un faire-part personnalisé et unique en choisissant soit :",
      },
      {
        title: "Le faire-part traditionnel",
        text: "c’est le billet d’invitation classique qui reprend les anciens codes de la réception. En carton ou en numérique (carré, rectangulaire, géométrique…), à vous de voir ! Mettez simplement votre message de faire-part en valeur avec une ambiance choisie selon l’allure de votre fête.",
      },
      {
        title: "Le faire-part à thème",
        text: ": il est séduisant dans la mesure où il reprend le thème de votre party. Il peut être fait à base d’un carton ou d’un document électronique conçu selon votre thématique. Il s’adapte facilement aux différents concepts pour ressortir votre côté ingénieux.",
      },
      {
        title: "Le faire-part artistique",
        text: "Mettant en avant l’art, l’esthétique, la création manuelle, il donne une touche artistique à votre fête",
      },
      {
        title: "Le faire-part schématique",
        text: ": il est plus moderne et remplace le faire-part classique ; il propose à travers un schéma la réalisation chronologique de votre événement avec l’illustration des différents temps forts de votre fête.",
      },
      {
        title: "Le faire-part éco-responsables",
        text: "vous voulez rappeler à vos invités l’importance de l’écologie et votre combat en faveur de l’environnement, ce modèle sera favorable ; il fait ressortir votre côté responsable et engagé en faveur de l’environnement",
      },
      {
        title: "Le faire-part humoristique",
        text: "Rien de tel qu’une invitation pleine d’humour pour décrit l’ambiance festive de la fête qui approche ; elle met les convives en condition pour un événement convivial et divertissant.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 12,
    priority: 3,
    img: "invitations.jpg",
  },
  {
    title: "Localisation",
    description:
      "Vous souhaitez un fête inoubliable, pensez dès lors à un lieu plein de charme.",
    infoParagraphs: [
      { text: "Nous vous proposons :" },
      {
        title: "Espace ouvert",
        text: "le lieu ouvert rend concret le désir d’une fête en plein air avec cette sensation originale d’une parfaite communion avec la nature. Il peut s’agir d’un parc, d’un patio, d’une terrasse, d’une péniche, d’une plage, du bord d’un lac (rivière, fleuve, cours d’eau…), d’une aire de jeu (golf, jardin publique…), d’un camping, d’une ferme… Innovez grâce à l’idée géniale d’un espace ouvert ; il vous sort de la monotonie des enclos, vous permet de donner une touche particulière à votre fête et vous démarque du déjà-vu.",
      },
      {
        title: "Espace fermé",
        text: "le lieu fermé est l’idéal pour toute cérémonie ; il vous met à l’abri de tout, vous donne le contrôle nécessaire sur l’événement et rassure vos invités. Vous pouvez opter pour une salle de fête, un restaurant, une maison d’hôtes, une boîte de nuit, pourquoi pas chez soi pour une ambiance familiale…",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 14,
    priority: 3,
    img: "location.jpg",
  },
  {
    title: "Musique",
    description:
      "La musique donne un air divertissant et marque les esprits. Sa place, tout au long de votre fête, se décline sous différents angles : elle peut être comme centre du spectacle, comme accompagnement pendant le déroulement de l’événement, comme régulation entre différentes activités de la fête ou comme la parfaite illustration du message que vous désirez transmettre.",
    infoParagraphs: [
      { text: "Ainsi, vous avez le choix entre :" },
      {
        title: "Un DJ",
        text: "Devenu aujourd’hui un allié incontournable de l’événementiel, il est la personne chargée du choix des morceaux et de l’animation de votre fête.",
      },
      {
        title: "Une musique en live",
        text: "Elle vous met dans le contexte des prestations musicales par des groupes, des chantres, des artistes et confère à votre fête une atmosphère authentique et originale.",
      },
      {
        title: "Une animation événementielle musicale",
        text: "Elle offre une présentation musicale variée pendant votre fête grâce aux concerts, aux showcases…",
      },
      {
        title: "Un Bling test et un Karaoké",
        text: "Ils font de vos invités, des participants actifs à la fête et les plongent dans leurs vieux souvenirs, leurs amours passés avec cette sensation de revivre profitant à fond de la cérémonie.",
      },
      {
        title: "Une musique classique ou traditionnelle",
        text: "À vous de voir compte tenu de vos invités, de l’ambiance et surtout du type de fête.",
      },
      {
        title: "Un style propre de musique",
        text: "Elle vous permet de donner une marque propre et écrire votre propre histoire selon votre événement",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 9,
    priority: 2,
    img: "music.jpg",
  },
  {
    title: "Repas",
    description:
      "La nourriture s’incruste de plus en plus pendant le party ; de ce fait, il est nécessaire, de réserver un stand pour la restauration de vos invités.",
    infoParagraphs: [
      { text: "Alors, désirez-vous :" },
      {
        title: "Une Repas soft",
        text: "Un menu simple faite de salade et nutriments légers pour une petite fête entre amis",
      },
      {
        title: "Un service traiteur rapide et garni",
        text: "Une composition de differents menus",
      },
      {
        title: "Une cuisine aux couleurs locales ou occidentales",
        text: "En fonction de vos invités et du type de fête vous pouvez commander des plats locaux, occidentaux ou mixtes",
      },
      {
        title: "Le barbecue",
        text: "Vous voulez consommez viandes et poissons, alors nous allumons le grill pour vos grillades.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 11,
    priority: 3,
    img: "food.jpg",
  },
  {
    title: "Boissons",
    description:
      "Contrairement à la nourriture, le breuvage est au centre d’une fête et constitue en soi un élément d’appréciation de votre événement",
    infoParagraphs: [
      {
        text: "Ainsi, déterminez ce que vous mettez à la disposition de vos invités avec un service de bar impeccable :",
      },
      {
        title: "L’eau fraiche",
        text: "L´eau c´est la vie ; c´est une obligation pour toute table",
      },
      {
        title: "Les boissons non alcoolisées",
        text: "En fonction de vos invités vous choisissez les variétés de boissons sans alcool à leur faire déguster. Vous respectez par ricochet les religions où la consommation d´alcool est interdite",
      },
      {
        title: "Les boissons alcoolisées",
        text: "Entre Whisky, liqueuers, vins rouges, vins blancs, vins mousseux et champagnes, nous étudions avec vous la composition de vos invités afin de faire un plan de boissons alcoolisés",
      },
      {
        title: "Les cocktails",
        text: "Pour une cérémonie avec bar ou à l´aire libre en journée, des cocktails sont des raffraichissants à proposer dans ce cadre à vos invités",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 11,
    priority: 3,
    img: "drinks.jpg",
  },
  {
    title: "Transport",
    description:
      "Le transport vous permet de structurer et d’organiser les déplacements et les mouvements pendant cet événement. Tenir compte de ce point vous facilite la mobilité et la fluidité des trafics au courant de votre fête. Vous pouvez louer des véhicules pour la journée et le weekend",
    ranges: [
      { min: 0, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 4,
    priority: 1,
    img: "shuttle.jpg",
  },
  {
    title: "Décoration",
    description:
      "La décoration du lieu de votre événement est un élément essentiel de la réussite et surtout ce qui reste dans les mémoires de vos invités. Choisissez vos couleurs, vos thèmes et laissez les professionnels transformez votre moment comme vous le rêvez",
    infoParagraphs: [
      { text: "Pensez aux éléments que vous voulez décorer :" },
      {
        title: "Décoration des chaises",
        text: "Voulez des chaises nues (par exemple Napoleon?), des chaises habillées en housses blanches ou autres couleurs?",
      },
      {
        title: "Décoration des tables",
        text: "Voulez vous des vases? des chemins de tables, de quoi doit être composé vos couverts?",
      },
      {
        title: "Fond de scène",
        text: "Voulez vous du morderne? du classique? du traditionnel? Quel thème est votre favori? Quelles sont vos couleurs?",
      },
      {
        title: "Coin photo",
        text: "Besoin de tapis rouge? Besoin de jeux de lumières? Besoin de ballons?",
      },
      {
        title: "Autres",
        text: "Voulez-vous que vos invités posent à leurs arrivée? Avec votre photo en background?",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 14,
    priority: 3,
    img: "decoration.jpg",
  },
  {
    title: "Photo/Video/Drone",
    description:
      "Vous êtes naturellement interessés à garder des souvenirs en laissant nos professionnels faire des photos et vidéos.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Photos",
        text: "Nos photographes se chargent de prendre les photos durant toutes les étapes de votre cérémonie et à la fin de vous produire un très bel album photo",
      },
      {
        title: "Vidéos",
        text: "Tout comme les photos, nos vidéates ne ratent aucun mouvement de votre cérémonie, vous permettant de bien les revivre plus tard",
      },
      {
        title: "Drones",
        text: "Dans les airs nous sommes aussi présents en vous réalisant des vues magnifiques",
      },
      {
        title: "Projecteurs",
        text: "Grâce à nos projecteurs et l´équipement nécessaire, des projections des videos, images et autres sont possibles durant l´événement",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 12,
    priority: 3,
    img: "photography.jpg",
  },
  {
    title: "Services spéciaux",
    description:
      "Des services spéciaux comme la mise à disposition des agents de sécurité, des hôtesses et hôtes sont également des éléments important qui contribent à la réussite de votre événement",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Sécurité",
        text: "La sécurité des biens et personnes est très important afin que vos ami(e)s et connaissance puissent bien se sentir durant toute votre cérémonie",
      },
      {
        title: "Hôtesses et hôtes",
        text: "Il n´est pas toujours nécessaire ce compter sur la famille ou les amis pour le service lors de votre cérémonie. Des personnes formées pour celà sauront rendrent vos invités très à l´aise.",
      },
      {
        title: "Master of Ceremony",
        text: "Un bon MC assure l´ambiance tout au long de la cérémonie, il y met de la structure, c´est pourquoi ce service est essentiel pour un bon déroulement des activités prévues",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 2,
    priority: 1,
    img: "specialservice.jpg",
  },
  {
    title: "Logistique",
    description:
      "Parfois nous avons un lieu d´événement, mais pas de chaises ni de tables et encore moins les couverts ou marmites chauffantes pour exposer les repas.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Chaises",
        text: "Plastiques, modernes ou VIP, nous avons differentes variantes à vous proposer",
      },
      {
        title: "Tables",
        text: "Carrées, rectangulaires, rondes ou ovales, en fonction de la dispotion de la salle on saura ce qui passe le mieux",
      },
      {
        title: "Marmites chauffantes",
        text: "Anciens modèles ou VIP, choisissez ce que vous voulez en fonction de la grandeur de votre cérémonie",
      },
      {
        title: "Tentes",
        text: "Sur differentes dimensions, standard ou VIP, nous mettons ce dont vous avez besoin à votre disposition",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 11,
    priority: 3,
    img: "logistic.jpg",
  },
];

const congress: EventCategoryFields[] = [
  {
    title: "Faire-Part",
    description:
      "Plus qu’un simple billet d’invitation, le faire-part détermine le style et transmet le message auprès des différentes parties prenantes de votre activité.",
    infoParagraphs: [
      {
        text: "Ainsi, vous avez la possibilité de choisir un faire-part personnalisé et unique entre :",
      },
      {
        title: "Le faire-part traditionnel",
        text: "c’est le billet d’invitation classique qui reprend les anciens codes de la réception. En carton ou en numérique (carré, rectangulaire, géométrique…), à vous de voir ! Mettez simplement votre message de faire-part et la thématique et les grandes lignes de votre rencontre ;",
      },
      {
        title: "Le faire-part à thème",
        text: ": il est séduisant dans la mesure où il reprend le thème de votre rencontre. Il peut être fait à base d’un carton ou d’un document électronique conçu selon votre thématique. Il s’adapte facilement aux différents concepts pour ressortir votre côté ingénieux en harmonie avec les travaux de votre événement ;",
      },
      {
        title: "Le faire-part artistique",
        text: "mettant en avant l’art, l’esthétique, la création manuelle, il donne une touche artistique et créative à l’événement ;",
      },
      {
        title: "Le faire-part schématique",
        text: "il est plus moderne et d’adapte parfaitement au planning de vos activités ; il propose à travers un schéma la réalisation chronologique de votre événement avec l’illustration des différents temps forts de vos travaux ;",
      },
      {
        title: "Le faire-part éco-responsables",
        text: "vous voulez rappeler à vos participants l’importance de l’écologie et le noble combat en faveur de l’environnement, ce modèle sera favorable ; il fait ressortir le côté responsable et engagé en harmonie avec les objectifs du développement durable.",
      },
      {
        title: "Le faire-part humoristique",
        text: "Il décrit l’ambiance festive de votre congrès avec un ton d’humour et de convivialité",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 3,
    img: "invitations.jpg",
  },
  {
    title: "Localisation",
    description:
      "Le lieu est un élément considérable dans l’organisation d’un congrès ou d’une conférence ; son choix dépend du public ou des participants et des questions débattues au cours des activités. L’espace fermé correspond le mieux à ce genre d’événement : il vous met à l’abri de tout, vous donne le contrôle nécessaire sur l’événement et met les participants à l’abri de toute distraction. Vous pouvez opter pour une salle de conférence, une maison d’hôtes ou tout espace fermé capable d’être d’accueillir l’événement.",
    infoParagraphs: [
      {
        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua",
      },
      {
        title: "Title",
        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 15,
    priority: 3,
    img: "location.jpg",
  },
  {
    title: "Repas",
    description:
      "La nourriture trouve sa place dans un congrès ou conférence pendant les petites pauses entre les interventions et la pause de midi. Ce sont des menus légers qui sont au menu afin d´éviter que les participants s´endorment. Les formes de repas ici varient entre assis et debout. Nous priviligions les formules Cocktails en milieu de matinée ou en fin d´après-midi et est en mode mange-debout afin que les invités puissent poser leur nourriture. Les invités se servent tout seuls.",
    infoParagraphs: [
      { text: "Alors, désirez-vous :" },
      {
        title: "Cocktail apéritif",
        text: "Ce menu est constitué de pièces chaudes et froides, de petits fours salés et sucrés ou encore de verrines servis avant un déjeuner ou un dîner assis",
      },
      {
        title: "Cocktail déjeunatoire",
        text: "Menu gourmand servi en guise de déjeuner à partir de midi",
      },
      {
        title: "Cocktail dînatoire",
        text: "Il est servi à partir de 19h en guise de dîner",
      },
      {
        title: "Formule buffet",
        text: "Il diffère du cocktail dans la mesure où les participants sont le plus souvent assis et le repas est plus consistant. Il peut être chaud ou froid",
      },
      {
        title: "Formule banquet",
        text: "Il est également appelé repas à l’assiette et est très solennel. Les participants sont assis et ils sont servis.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 11,
    priority: 3,
    img: "food.jpg",
  },
  {
    title: "Boissons",
    description:
      "Les boissons et particulièrement l´eau sont essentiel pour un bon raffraîchissement des paticipants. Ici les alcools seront plus proposés au dîner après le deroulement des assises.",
    infoParagraphs: [
      {
        text: "Ainsi, déterminez ce que vous mettez à la disposition de vos invités avec un service de bar impeccable :",
      },
      {
        title: "L’eau fraiche",
        text: "L´eau c´est la vie ; c´est une obligation pendant le deroulement du congrès ou seminaire.",
      },
      {
        title: "Les boissons non alcoolisées",
        text: "Elles peuvent accompagner l´eau pendant les assises et sont naturellement à table pour le repas",
      },
      {
        title: "Les boissons alcoolisées",
        text: "Du vin rouge ou du blanc sont les boissons adaptées au dîner après une journée de seminaire ou congrès.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 3,
    img: "drinks.jpg",
  },
  {
    title: "Transport",
    description:
      "Le transport vous permet de structurer et d’organiser les déplacements et les mouvements pendant le congrès. Tenir compte de ce point vous facilite la mobilité et la fluidité des trafics au courant de votre fête. Vous pouvez louer des véhicules pour la journée, le weekend, la semaine ou des mois",
    ranges: [
      { min: 0, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 7,
    priority: 2,
    img: "shuttle.jpg",
  },
  {
    title: "Décoration",
    description:
      "La décoration du lieu de votre même pour votre Corporte Event est un élément essentiel de la réussite et surtout ce qui reste dans les mémoires de vos invités. Choisissez vos couleurs, vos thèmes et laissez les professionnels transformer votre lieu de travail en lieu de plaisir",
    infoParagraphs: [
      { text: "Pensez aux éléments que vous voulez décorer :" },
      {
        title: "Décoration des chaises",
        text: "Voulez des chaises nues (par exemple Napoleon?), des chaises habillées en housses blanches ou autres couleurs?",
      },
      {
        title: "Décoration des tables",
        text: "Voulez vous des vases? des chemins de tables, de quoi doit être composé vos couverts?",
      },
      {
        title: "Fond de scène",
        text: "Voulez vous du morderne? du classique? du traditionnel? Quel thème est votre favori? Quelles sont vos couleurs?",
      },
      {
        title: "Coin photo",
        text: "Besoin de tapis rouge? Besoin de jeux de lumières? Besoin de ballons?",
      },
      {
        title: "Autres",
        text: "Voulez-vous que vos invités posent à leurs arrivée? Avec votre photo en background?",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 13,
    priority: 3,
    img: "decoration.jpg",
  },
  {
    title: "Photo/Video/Drone",
    description:
      "Vous êtes naturellement interessés à garder des souvenirs en laissant nos professionnels faire des photos et vidéos.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Photos",
        text: "Nos photographes se chargent de prendre les photos durant toutes les étapes de votre cérémonie et à la fin de vous produire un très bel album photo",
      },
      {
        title: "Vidéos",
        text: "Tout comme les photos, nos vidéates ne ratent aucun mouvement de votre cérémonie, vous permettant de bien les revivre plus tard",
      },
      {
        title: "Drones",
        text: "Dans les airs nous sommes aussi présents en vous réalisant des vues magnifiques",
      },
      {
        title: "Projecteurs",
        text: "Grâce à nos projecteurs et l´équipement nécessaire, des projections des videos, images et autres sont possibles durant l´événement",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 11,
    priority: 3,
    img: "photography.jpg",
  },
  {
    title: "Services spéciaux",
    description:
      "Des services spéciaux comme la mise à disposition des agents de sécurité, des hôtesses et hôtes sont également des éléments important qui contribent à la réussite de votre événement",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Sécurité",
        text: "La sécurité des biens et personnes est très important afin que vos ami(e)s et connaissance puissent bien se sentir durant toute votre cérémonie",
      },
      {
        title: "Hôtesses et hôtes",
        text: "Il n´est pas toujours nécessaire ce compter sur la famille ou les amis pour le service lors de votre cérémonie. Des personnes formées pour celà sauront rendrent vos invités très à l´aise.",
      },
      {
        title: "Master of Ceremony",
        text: "Un bon MC assure l´ambiance tout au long de la cérémonie, il y met de la structure, c´est pourquoi ce service est essentiel pour un bon déroulement des activités prévues",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 8,
    priority: 2,
    img: "specialservice.jpg",
  },
  {
    title: "Logistique",
    description:
      "Parfois nous avons un lieu d´événement, mais pas de chaises ni de tables et encore moins les couverts ou marmites chauffantes pour exposer les repas.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Chaises",
        text: "Plastiques, modernes ou VIP, nous avons differentes variantes à vous proposer",
      },
      {
        title: "Tables",
        text: "Carrées, rectangulaires, rondes ou ovales, en fonction de la dispotion de la salle on saura ce qui passe le mieux",
      },
      {
        title: "Marmites chauffantes",
        text: "Anciens modèles ou VIP, choisissez ce que vous voulez en fonction de la grandeur de votre cérémonie",
      },
      {
        title: "Tentes",
        text: "Sur differentes dimensions, standard ou VIP, nous mettons ce dont vous avez besoin à votre disposition",
      },
      {
        title: "Projecteurs",
        text: "Nécessaire pour la projection des présentations lors de votre seminaire, ils peuvent être mis à disposition",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 15,
    priority: 3,
    img: "logistic.jpg",
  },
];

const festival: EventCategoryFields[] = [
  {
    title: "Faire-part",
    description:
      "Entre la création artistique et le savoir-faire, le faire-part est devenu plus qu’un simple billet d’invitation. Il véhicule le ton de votre activité festive auprès des personnes que vous désirez conviées à cet événement.",
    infoParagraphs: [
      {
        text: "Vous pouvez donc opter pour un faire-part personnalisé et unique en choisissant soit :",
      },
      {
        title: "Le faire-part traditionnel",
        text: "c’est le billet d’invitation classique qui reprend les anciens codes de la réception. En carton ou en numérique (carré, rectangulaire, géométrique…), à vous de voir ! Mettez simplement votre message de faire-part en valeur avec une ambiance choisie selon l’allure de votre festival.",
      },
      {
        title: "Le faire-part à thème",
        text: ": il est séduisant dans la mesure où il reprend le thème de votre festival. Il peut être fait à base d’un carton ou d’un document électronique conçu selon votre thématique. Il s’adapte facilement aux différents concepts pour ressortir votre côté ingénieux.",
      },
      {
        title: "Le faire-part artistique",
        text: "Mettant en avant l’art, l’esthétique, la création manuelle, il donne une touche artistique à votre festival",
      },
      {
        title: "Le faire-part schématique",
        text: ": il est plus moderne et remplace le faire-part classique ; il propose à travers un schéma la réalisation chronologique de votre événement avec l’illustration des différents temps forts de votre festival.",
      },
      {
        title: "Le faire-part éco-responsables",
        text: "vous voulez rappeler à vos invités l’importance de l’écologie et votre combat en faveur de l’environnement, ce modèle sera favorable ; il fait ressortir votre côté responsable et engagé en faveur de l’environnement",
      },
      {
        title: "Le faire-part humoristique",
        text: "Rien de tel qu’une invitation pleine d’humour pour décrit l’ambiance festive du festivalqui approche ; elle met les convives en condition pour un événement convivial et divertissant.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 5,
    priority: 2,
    img: "invitations.jpg",
  },
  {
    title: "Localisation",
    description:
      "Le lieu est un élément considérable dans l’organisation d’un festival ; son choix peut être rattaché aux liens sociaux culturels et historiques",
    infoParagraphs: [
      {
        text: "Voilà pourquoi, en fonction du public cible, du message et de l’orientation de cet événement, vous pouvez opter :",
      },
      {
        title: "Un espace ouvert ",
        text: "Il plus conseillé dans la plupart des cas, il nous rapproche plus à la vie réelle et à la forte sensation de communion avec la nature. Il peut se célébrer dans un parc, dans une avenue, une plage, une aire de jeu, d’un jardin public… ",
      },
      {
        title: "Un espace fermé",
        text: "Il est conseillé pour un public restreint, Le lieu fermé est l’idéal d’espace pour un festival responsable et restreint ; il vous met à l’abri de tout et vous donne le contrôle nécessaire sur l’événement.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 12,
    priority: 3,
    img: "location.jpg",
  },
  {
    title: "Musique",
    description:
      "La musique donne un air divertissant et marque les esprits tout au long du festival. Sa place, tout au long de cet événement, se décline sous différents angles : elle peut être comme centre du spectacle, comme accompagnement pendant le déroulement de l’événement, comme régulation entre différentes activités de la fête.",
    infoParagraphs: [
      { text: "Ainsi, vous avez le choix entre :" },
      {
        title: "Un DJ",
        text: "Devenu aujourd’hui un allié incontournable de l’événementiel, il est la personne chargée du choix des morceaux et de l’animation de votre festival.",
      },
      {
        title: "Une musique en live",
        text: "Elle vous met dans le contexte des prestations musicales par des groupes, des chantres, des artistes et confère à votre festival une atmosphère authentique et originale.",
      },
      {
        title: "Une animation événementielle musicale",
        text: "Elle offre une présentation musicale variée pendant votre festival grâce aux concerts, aux showcases…",
      },
      {
        title: "Un Bling test et un Karaoké",
        text: "Ils font de vos invités, des participants actifs au festival et les plongent dans leurs vieux souvenirs, leurs amours passés avec cette sensation de revivre profitant à fond du festival",
      },
      {
        title: "Une musique traditionnelle ou culturelle",
        text: "Pour une texture spéciale selon la catégorie du public",
      },
      {
        title: "Un style propre de musique",
        text: "Il vous permet de donner une marque propre et écrire votre propre histoire selon votre festival",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 12,
    priority: 3,
    img: "music.jpg",
  },
  {
    title: "Repas",
    description:
      "La nourriture s’incruste de plus en plus pendant le festival ; de ce fait, il est nécessaire, de prévoir des stands où les participants viendront manger. Dans un cadre restreint, des repas locaux ou qui ont un lien avec le festival peuvent être cuisinés et mis à disposition",
    infoParagraphs: [
      { text: "Alors, désirez-vous :" },
      {
        title: "Cocktail apéritif",
        text: "Ce menu est constitué de pièces chaudes et froides, de petits fours salés et sucrés ou encore de verrines servis avant un déjeuner ou un dîner assis",
      },
      {
        title: "Cocktail déjeunatoire",
        text: "Menu gourmand servi en guise de déjeuner à partir de midi",
      },
      {
        title: "Cocktail dînatoire",
        text: "Il est servi à partir de 19h en guise de dîner",
      },
      {
        title: "Formule buffet",
        text: "Il diffère du cocktail dans la mesure où les participants sont le plus souvent assis et le repas est plus consistant. Il peut être chaud ou froid",
      },
      {
        title: "Formule banquet",
        text: "Il est également appelé repas à l’assiette et est très solennel. Les participants sont assis et ils sont servis.",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 9,
    priority: 2,
    img: "food.jpg",
  },
  {
    title: "Boissons",
    description:
      "Contrairement à la nourriture, le breuvage est au centre d’un festival et constitue en soi un élément d’appréciation de votre activité.",
    infoParagraphs: [
      {
        text: "Ainsi, déterminez ce que vous mettez à la disposition de vos invités avec un service de bar impeccable :",
      },
      {
        title: "L’eau fraiche",
        text: "L´eau c´est la vie ; c´est une obligation pour toute table",
      },
      {
        title: "Les boissons non alcoolisées",
        text: "En fonction de vos invités vous choisissez les variétés de boissons sans alcool à leur faire déguster. Vous respectez par ricochet les religions où la consommation d´alcool est interdite",
      },
      {
        title: "Les boissons alcoolisées",
        text: "Entre Whisky, liqueuers, vins rouges, vins blancs, vins mousseux et champagnes, nous étudions avec vous la composition de vos invités afin de faire un plan de boissons alcoolisés",
      },
      {
        title: "Les cocktails",
        text: "Pour une cérémonie avec bar ou à l´aire libre en journée, des cocktails sont des raffraichissants à proposer dans ce cadre à vos invités",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 11,
    priority: 3,
    img: "drinks.jpg",
  },
  {
    title: "Transport",
    description:
      "Le transport vous permet de structurer et d’organiser les déplacements et les mouvements pendant le festival. Tenir compte de ce point vous facilite la mobilité et la fluidité des trafics au courant de votre festival. Vous pouvez louer des véhicules pour la journée, le weekend, la semaine ou des mois",
    ranges: [
      { min: 0, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 6,
    priority: 2,
    img: "shuttle.jpg",
  },
  {
    title: "Décoration",
    description:
      "La décoration du lieu de votre événement est un élément essentiel de la réussite et surtout ce qui reste dans les mémoires de vos invités. Choisissez vos couleurs, vos thèmes et laissez les professionnels transformez votre moment comme vous le rêvez",
    infoParagraphs: [
      { text: "Pensez aux éléments que vous voulez décorer :" },
      {
        title: "Décoration des chaises",
        text: "Voulez des chaises nues (par exemple Napoleon?), des chaises habillées en housses blanches ou autres couleurs?",
      },
      {
        title: "Décoration des tables",
        text: "Voulez vous des vases? des chemins de tables, de quoi doit être composé vos couverts?",
      },
      {
        title: "Fond de scène",
        text: "Voulez vous du morderne? du classique? du traditionnel? Quel thème est votre favori? Quelles sont vos couleurs?",
      },
      {
        title: "Coin photo",
        text: "Besoin de tapis rouge? Besoin de jeux de lumières? Besoin de ballons?",
      },
      {
        title: "Autres",
        text: "Voulez-vous que vos invités posent à leurs arrivée? Avec votre photo en background?",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 12,
    priority: 3,
    img: "decoration.jpg",
  },
  {
    title: "Photo/Video/Drone",
    description:
      "Vous êtes naturellement interessés à garder des souvenirs en laissant nos professionnels faire des photos et vidéos.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Photos",
        text: "Nos photographes se chargent de prendre les photos durant toutes les étapes de votre cérémonie et à la fin de vous produire un très bel album photo",
      },
      {
        title: "Vidéos",
        text: "Tout comme les photos, nos vidéates ne ratent aucun mouvement de votre cérémonie, vous permettant de bien les revivre plus tard",
      },
      {
        title: "Drones",
        text: "Dans les airs nous sommes aussi présents en vous réalisant des vues magnifiques",
      },
      {
        title: "Projecteurs",
        text: "Grâce à nos projecteurs et l´équipement nécessaire, des projections des videos, images et autres sont possibles durant l´événement",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 11,
    priority: 3,
    img: "photography.jpg",
  },
  {
    title: "Services spéciaux",
    description:
      "Des services spéciaux comme la mise à disposition des agents de sécurité, des hôtesses et hôtes sont également des éléments important qui contribent à la réussite de votre événement",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Sécurité",
        text: "La sécurité des biens et personnes est très important afin que vos ami(e)s et connaissance puissent bien se sentir durant toute votre cérémonie",
      },
      {
        title: "Hôtesses et hôtes",
        text: "Il n´est pas toujours nécessaire ce compter sur la famille ou les amis pour le service lors de votre cérémonie. Des personnes formées pour celà sauront rendrent vos invités très à l´aise.",
      },
      {
        title: "Master of Ceremony",
        text: "Un bon MC assure l´ambiance tout au long de la cérémonie, il y met de la structure, c´est pourquoi ce service est essentiel pour un bon déroulement des activités prévues",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 10,
    priority: 3,
    img: "specialservice.jpg",
  },
  {
    title: "Logistique",
    description:
      "Parfois nous avons un lieu d´événement, mais pas de chaises ni de tables et encore moins les couverts ou marmites chauffantes pour exposer les repas.",
    infoParagraphs: [
      { text: "Nous vous proposons:" },
      {
        title: "Chaises",
        text: "Plastiques, modernes ou VIP, nous avons differentes variantes à vous proposer",
      },
      {
        title: "Tables",
        text: "Carrées, rectangulaires, rondes ou ovales, en fonction de la dispotion de la salle on saura ce qui passe le mieux",
      },
      {
        title: "Marmites chauffantes",
        text: "Anciens modèles ou VIP, choisissez ce que vous voulez en fonction de la grandeur de votre cérémonie",
      },
      {
        title: "Tentes",
        text: "Sur differentes dimensions, standard ou VIP, nous mettons ce dont vous avez besoin à votre disposition",
      },
    ],
    ranges: [
      { min: 1, max: 5 },
      { min: 5, max: 10 },
      { min: 10, max: 15 },
    ],
    percent: 12,
    priority: 3,
    img: "logistic.jpg",
  },
];

export interface EventType {
  title: string;
  description?: string;
  eventCategories: EventCategoryFields[];
  img: string;
}

export const EVENTS: EventType[] = [
  {
    title: "Mariage",
    description:
      'Le mariage est une union de deux cœurs, un accord de deux corps, une harmonie de deux volontés, un rapprochement de deux points de vue. Par le mariage, vous dites "oui" à l’autre devant Dieu et devant les hommes. Et, votre rêve, c’est de faire de cet événement, le meilleur moment de votre vie en l’inscrivant dans les annales de vos souvenirs inoubliables et de ceux de vos invités. Ceci est possible grâce à la combinaison harmonieuse de plusieurs paramètres et de plusieurs éléments dans le but de créer un effet subliminal et sensationnel qui se rapprochent le mieux à vos attentes.',
    eventCategories: wedding,
    img: "wedding.jpg",
  },
  {
    title: "Obsèques",
    description:
      "Vous avez perdu un être cher et êtes inconsolable! Ce moment est unique, ce moment est dur. Chacun digère la douleur à sa manière et le plus important est d´être naturel. Malheuresement à côté de la gestion de vos émotions, vous devez penser à l´organisation des obsèques du disparu. Pourquoi troubler vos pleurs? Laissez-vous accompagner, laissez nous prendre les choses en main de organiser un bon Aurevoir à votre personne bien aimée.",
    eventCategories: funeral,
    img: "funeral.jpg",
  },
  {
    title: "Anniversaire",
    description:
      "L’anniversaire est la célébration d’un souvenir vécu heureux ou malheureux ancré dans nos mémoires. En organisant un événement de commémoration permet aux uns et aux autres de montrer une marque particulière d’attention à la date qui marque le fait vécu. L’orientation de l’anniversaire dépend du type d’événement et de l’allure accordée.",
    eventCategories: birthday,
    img: "birthday.jpg",
  },
  {
    title: "Faire la fête",
    description:
      "Le plus humain en nous, êtres humains, c’est cette capacité de pouvoir faire la fête, se détendre, s’amuser au tour d’une activité de divertissement. A cet effet, vous êtes sur point d’organiser une fête, nous vous accompagnons pour une planification réussie.",
    eventCategories: party,
    img: "party.jpg",
  },
  {
    title: "Congrès/Séminaire/Conférence",
    description:
      "Vous êtes sur le point d’organiser une activité scientifique ou intellectuelle, un événement d´entreprise, une conférence, un séminaire, un colloque ou une présentation de produit et vous ne savez où commencer, la solution à portée de main : confiez nous votre événement quel que soit sa nature et nous allons nous adapter à votre besoin",
    eventCategories: congress,
    img: "congress.jpg",
  },
  {
    title: "Festival",
    description:
      "Le festival est une manifestation festive ouverte au grand public et communautaire. Son organisation met en œuvre plusieurs ressources ce qui justifie sa complexité. Cependant, simplifiez-vous la tâche en prenant en compte tous les points. Nous vous accompagnons",
    eventCategories: festival,
    img: "festival.jpg",
  },
];
