import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Box } from "@mui/system";
import { Button, Grid, Link, Typography } from "@mui/material";
import { Wizzard } from "./controls/Wizzard";
import { EventSelector } from "./controls/EventSelector";
import { EventType } from "./config/eventsConfig";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import { SocialDistance } from "@mui/icons-material";

export function capitalize(c: string) {
  return `${c.substring(0, 1).toUpperCase()}${c.substring(1)}`;
}

const social = [
  { href: "https://www.facebook.com/jtm.events.237", icon: <FacebookIcon /> },
  {
    href: "https://www.instagram.com/jtm.events.237/",
    icon: <InstagramIcon />,
  },
  {
    href: "https://www.youtube.com/channel/UCAXsgJ8cC0u8w1H8vyrItVQ/featured",
    icon: <YouTubeIcon />,
  },
  { href: "https://twitter.com/jtm_events_237", icon: <TwitterIcon /> },
  { href: "https://www.tiktok.com/@jtm.events.237", icon: "TikTok" },
];

function App() {
  let [event, setEvent] = React.useState<EventType>();

  return (
    <div className="App">
      {!event && <EventSelector setEvent={setEvent} />}

      {event && (
        <Box
          sx={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}
          padding={2}
        >
          <Wizzard eventType={event} setEvent={setEvent} />
        </Box>
      )}

      <Box m={2}>
        {/* <Link target={"_blank"} href={"https://linktr.ee/jtm.events.237"}>
          Notre présence dans les réseaux sociaux
        </Link> */}
        {social.map((s) => {
          return (
            <Link m={2} target={"_blank"} href={s.href}>
              {s.icon}
            </Link>
          );
        })}
      </Box>
    </div>
  );
}

export default App;
