import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { capitalize } from "../App";

import { sendMail } from "./MailGun";
import { Proposal } from "./Wizzard";

interface CardArgs {
  proposal: Proposal;
}

interface FormValues {
  name: string;
  email: string;
  phone: string;
  guests: string;
  place: string;
  comment: string;
}

export default function RequestProposal(args: CardArgs) {
  const { proposal } = args;

  const prioMapping = [
    "Pas important",
    "Importance basse",
    "Importance moyenne",
    "Très important",
  ];

  const generateProposal = (data: FormValues) => {
    // console.log("🚀 ~ data", data);
    // console.log("🚀 ~ 'generate Proposal'", proposal);
    let html = "";

    html += `<h4>Proposal Request</h4>`;
    html += `<br /><strong>Name:</strong>  ${data.name}`;
    html += `<br /><strong>Phone:</strong> <a href="tel:${data.phone}">${data.phone}</a></small>`;
    html += `<br /><strong>Contact:</strong> <a href="mailto:${data.email}">${data.email}</a></small>`;
    html += `<br /><br /><strong>Event:</strong> ${proposal.eventType.title}`;
    html += `<br /><strong>Place:</strong> ${data.place}`;
    html += `<br /><strong>Guests:</strong> ${data.guests}`;
    html += `<br /><br /><strong>Comment:</strong> ${data.comment || "-"}`;

    html += `<h5>Total Budget: ${proposal.budget.toLocaleString()} XAF</h6><br />`;
    html += `<label>Budget Breakdown:</label> `;
    html += `<table style="width: 500px; border:1px solid grey; padding:10px"><tr><td>Category</td><td>Priority</td><td>Budget (XAF)</td>`;
    const proposalTable = proposal.eventType.eventCategories.map((category) => {
      // const category = proposal.categories[key as keyof EventCategories]
      console.log("cat.value", category.value);
      return `<tr><td>${capitalize(category.title)}</td><td>${
        prioMapping[category.priority]
      }</td><td>${category.value?.toLocaleString()} XAF</td></tr>`;
    });
    html += proposalTable.join("");

    html += "</table>";

    sendProposal(html);
  };

  const sendProposal = async (html: string) => {
    console.log(
      "🚀 ~ file: ResultCard.tsx ~ line 61 ~ sendProposal ~ 'Sending Proposal'",
      "Sending Proposal"
    );
    const sendRes = await sendMail("test", html);
    if (sendRes.status === 200) {
      console.log("🚀 ~ sendRes", sendRes);
      setSendStatus("success");
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    // height: 60,
    padding: 5,
    lineHeight: "60px",
  }));

  let [sendStatus, setSendStatus] = React.useState<
    "success" | "error" | undefined
  >();

  return (
    <Box
      mb={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: 3,
          maxWidth: "500px",
          width: "fit-content",
          backgroundColor: "white",
        }}
      >
        {sendStatus === undefined && (
          <Box>
            <Typography variant="body1" fontWeight={"bolder"} marginBottom={3}>
              Request Proposal for {capitalize(proposal.eventType.title)} to JTM
            </Typography>
            <Typography paragraph>
              This proposal is for free.
              <br />
              We will contact you for further information
            </Typography>
            <FormContainer onSuccess={generateProposal}>
              <Box
                sx={{
                  "& > :not(style)": { m: 1 },
                }}
              >
                {/* <Box
                        component="div"
                        sx={{
                            '& > :not(style)': { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                    > */}

                <Typography variant="overline" display={"block"}>
                  Contact Information
                </Typography>
                <FormControl sx={{ m: 2, width: "30ch" }}>
                  <TextFieldElement
                    label="Name"
                    name="name"
                    aria-describedby="name-helper-text"
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: "30ch" }}>
                  {/* <InputLabel htmlFor="email">Email address</InputLabel> */}
                  <TextFieldElement
                    label="eMail"
                    name="email"
                    id="email"
                    aria-describedby="email-helper-text"
                  />
                  {/* <FormHelperText id="email-helper-text">JTM will never share your email.</FormHelperText> */}
                </FormControl>
                <FormControl sx={{ m: 2, width: "30ch" }}>
                  {/* <InputLabel htmlFor="phone">Phone</InputLabel> */}
                  <TextFieldElement
                    label="Phone"
                    name="phone"
                    id="phone"
                    aria-describedby="phone-helper-text"
                  />
                </FormControl>
                {/* <Divider /> */}
                <Typography variant="overline" display={"block"}>
                  Event Information
                </Typography>
                <FormControl sx={{ m: 2, width: "15ch" }}>
                  {/* <InputLabel htmlFor="Guests">Guests</InputLabel> */}
                  <TextFieldElement
                    label="Invités"
                    name="guests"
                    id="guests"
                    aria-describedby="guests-helper-text"
                  />
                  <FormHelperText id="guests-helper-text">
                    Nbre d´invités
                  </FormHelperText>
                </FormControl>
                <FormControl sx={{ m: 2, width: "30ch" }}>
                  {/* <InputLabel htmlFor="Place">Place</InputLabel> */}
                  <TextFieldElement
                    label="Lieu de l´événement"
                    name="place"
                    id="place"
                    aria-describedby="place-helper-text"
                  />
                  <FormHelperText id="place-helper-text"></FormHelperText>
                </FormControl>
                <FormControl sx={{ m: 2, width: "100%" }}>
                  {/* <InputLabel htmlFor="Place">Place</InputLabel> */}
                  <TextFieldElement
                    rows={3}
                    label="Commentaires"
                    name="comment"
                    id="comment"
                    aria-describedby="place-helper-text"
                  />
                  {/* <FormHelperText id="place-helper-text"></FormHelperText> */}
                </FormControl>
              </Box>
              <Box>
                <Button
                  disabled={sendStatus !== undefined}
                  type={"submit"}
                  variant={"outlined"}
                  color={"primary"}
                >
                  ENVOI GRATUIT DE VOTRE DEMANDE
                </Button>
              </Box>
            </FormContainer>
          </Box>
        )}
        {sendStatus === "success" && (
          <Box>
            <Typography variant="body1" fontWeight={"bolder"} gutterBottom>
              Request was send to JTM.
            </Typography>
            <Typography variant="caption">
              We will contact you with a detailed proposal, soon.
            </Typography>
          </Box>
        )}
        {sendStatus === "error" && (
          <Box>
            <Typography variant="body1" fontWeight={"bolder"} gutterBottom>
              Request could not be send to JTM.
            </Typography>
            <Typography variant="caption">Please try again later.</Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
