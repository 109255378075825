import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  IconButtonProps,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { red } from "@mui/material/colors";
import InfoIcon from "@mui/icons-material/Info";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { EventCategories, Proposal } from "./Wizzard";
import { sendMail } from "./MailGun";
import { request } from "http";
import { capitalize } from "../App";
import { EventType, EventCategoryFields } from "../config/eventsConfig";

interface CardArgs {
  // eventCategories: EventCategoryFields[],
  eventType: EventType;
  requestProposal: (proposal: Proposal) => void;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const prioMapping = ["Not relevant", "Low", "Medium", "High"];

export default function ResultCard(args: CardArgs) {
  const [budget, setBudget] = React.useState(10000);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const { eventType, requestProposal } = args;
  const eventCategories = eventType.eventCategories;

  const [resultEventCategories, setResultEventCategories] =
    React.useState(eventCategories);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const generateProposal = () => {
    console.log("🚀 ~ resultEventCategories", resultEventCategories);
    const proposal = {
      budget: budget,
      eventType: {
        ...eventType,
        eventCategories: resultEventCategories,
      },
      // categories: resultEventCategories
    };
    console.log("🚀 ~ proposal", proposal);
    requestProposal(proposal);
  };

  const sendProposal = () => {
    console.log(
      "🚀 ~ file: ResultCard.tsx ~ line 61 ~ sendProposal ~ 'Sending Proposal'",
      "Sending Proposal"
    );
    const sendRes = sendMail("Propocal");
  };

  const updateBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    console.log("init stuff...");
    for (let i = 0; i < resultEventCategories.length; i++) {
      setResultEventCategories((prev) => {
        // const key = keys[i]
        // const index = prev.findIndex((c) => c.title === target.name)
        const index = i;
        const percent = prev[index].percent || 0;
        console.log(
          `set key ${target.name} to value ${
            (Number(percent) / 100) * Number(target.value)
          }`
        );
        prev[index].value = Math.round(
          (Number(percent) / 100) * Number(target.value)
        );
        return prev;
      });
    }
    setBudget(Number(target.value));
  };
  const updateCats = (
    prev: EventCategoryFields[],
    index: number,
    value: string | boolean | number,
    field?: string
  ) => {
    let prio = 0;
    const ranges = prev[index].ranges;

    if (field === "percent") {
      // const percent = prev[key as keyof EventCategories]["ranges"][Number(value) - 1] || 0
      prev[index].percent = Number(value);
      prev[index].value = Math.round((Number(value) / 100) * budget);

      for (let i = 0; i < ranges.length; i++) {
        if (ranges[i].min < Number(value) && ranges[i].max >= Number(value)) {
          prio = i + 1;
        }
      }
    } else if (field === "priority") {
      prev[index].priority = Number(value);
    } else if (field === "value") {
      const percent = Math.round((Number(value) / budget) * 100);
      prev[index].percent = percent;
      prev[index].value = Number(value);

      for (let i = 0; i < ranges.length; i++) {
        if (ranges[i].min < percent && ranges[i].max >= percent) {
          prio = i + 1;
          break;
        }
      }
    } else {
      prev[index][field as keyof EventCategoryFields] = value as never;
    }

    return prev;
  };
  const changeCategories = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    console.log("handle", target);
    let prev = [...resultEventCategories];

    const key = target.name.split("-")[0];
    const field = target.name.split("-")[1];

    setResultEventCategories(
      updateCats(prev, Number(key), target.value, field)
    );
  };

  const toggleEdit = (key: number, val?: boolean, autoFocus?: "percent") => {
    const value = val || !resultEventCategories[key].edit;
    if (autoFocus === "percent") {
      setResultEventCategories(
        updateCats([...resultEventCategories], key, true, "focusPercent")
      );
      setResultEventCategories(
        updateCats([...resultEventCategories], key, false, "focusValue")
      );
    } else {
      setResultEventCategories(
        updateCats([...resultEventCategories], key, false, "focusPercent")
      );
      setResultEventCategories(
        updateCats([...resultEventCategories], key, true, "focusValue")
      );
    }

    setResultEventCategories(
      updateCats([...resultEventCategories], key, value, "edit")
    );
  };

  const toggleInfo = (key: number, val?: boolean) => {
    const value = val || !resultEventCategories[key].expanded;
    setResultEventCategories(
      updateCats([...resultEventCategories], key, value, "expanded")
    );
  };

  React.useEffect(() => {
    // const keys = Object.keys(resultEventCategories)
    // console.log("🚀 ~ file: ResultCard.tsx ~ line 123 ~ React.useEffect ~ resultEventCategories", resultEventCategories)
    let prev = [...eventCategories];
    for (let i = 0; i < prev.length; i++) {
      // const key = keys[i]
      const percent = prev[i].percent || 0;
      console.log(
        `set key ${prev[i].title} to value ${(Number(percent) / 100) * budget}`
      );
      prev[i].value = Math.round((Number(percent) / 100) * budget);
    }
    setResultEventCategories(prev);
  }, []);

  console.log("resultEventCategories", resultEventCategories);

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: "60px",
  }));
  const categories = Object.keys(eventCategories);

  return (
    <Box>
      <Box
        mb={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" marginBottom={3}>
          Proposition pour votre {capitalize(eventType.title)}
        </Typography>
        <Paper
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: 3,
            maxWidth: "500px",
            width: "fit-content",
            backgroundColor: "white",
          }}
        >
          <Typography variant="overline">Entrez votre budget</Typography>
          <Typography variant="caption" marginBottom={3}>
            Vous pouvez entrer le budget que vous voulez mettre à disposition
            pour votre événement et obtenir un apercu de la repartition de
            celui-ci sur les différentes paquets. Vous avez la possibilité de
            changer le pourcentage sur les differents paquets (En faisant
            attention que la somme donne toujours 100%) ou modifier les
            montants.
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <FormControl sx={{ m: 2, width: "20ch" }}>
              <InputLabel htmlFor="budget-input">Budget</InputLabel>
              <OutlinedInput
                onChange={updateBudget}
                value={budget}
                id="budget-input"
                label="Budget"
                endAdornment="XAF"
                aria-describedby="budget-input-helper-text"
              />
              {/* <TextField onChange={updateBudget} value={budget} id="outlined-basic" label="Budget" variant="outlined" aria-describedby="budget-input-helper-text" /> */}
              <FormHelperText id="budget-input-helper-text">
                Entrer votre budget
              </FormHelperText>
            </FormControl>
            <Button
              variant="outlined"
              color="primary"
              sx={{ backgroundColor: "white" }}
              onClick={() => generateProposal()}
            >
              Demande de proposition
            </Button>
          </Box>
        </Paper>
      </Box>

      <Typography mb={4} variant="h5">
        Overview
      </Typography>

      <Grid display={"flex"} justifyContent={"center"} container spacing={2}>
        {resultEventCategories &&
          resultEventCategories.map((cat, index) => {
            // const cat = resultEventCategories[index]
            return (
              <Grid key={`${index}-category`} item xs={12} sm={6} md={4} xl={3}>
                <Card sx={{ maxWidth: 500, marginBottom: 2 }}>
                  <CardHeader
                    avatar={
                      <Avatar
                        onClick={() => toggleEdit(index, !cat.edit, "percent")}
                        sx={{ bgcolor: red[500], cursor: "pointer" }}
                        aria-label="percent"
                      >
                        <Typography variant="caption">
                          {cat.percent}%
                        </Typography>
                      </Avatar>
                    }
                    action={
                      <IconButton onClick={() => toggleEdit(index)}>
                        <Typography>
                          {(cat.value || 0).toLocaleString("de")}XAF
                        </Typography>
                      </IconButton>
                    }
                    title={<Typography>{capitalize(cat.title)}</Typography>}
                    subheader={`Prio: ${prioMapping[cat.priority]}`}
                  />

                  <CardMedia
                    component="img"
                    height="194"
                    image={`img/${cat.img || eventType.img}`}
                    alt="Paella dish"
                  />
                  {cat.edit && (
                    <CardContent>
                      <Box
                        display={"flex"}
                        alignContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                      >
                        <Box component="form" noValidate autoComplete="off">
                          <Typography variant="body2" gutterBottom>
                            Adjust Amount/Percentage
                          </Typography>
                          <FormControl
                            sx={{ m: 1, mb: 3, mt: 2, width: "15ch" }}
                            variant="outlined"
                          >
                            <InputLabel htmlFor="percent-input">
                              Percent of budget
                            </InputLabel>
                            <OutlinedInput
                              onChange={changeCategories}
                              name={`${index}-percent`}
                              value={cat.percent}
                              autoFocus={cat.focusPercent}
                              label={"Percent of budget"}
                              id="percent-input"
                              endAdornment={"%"}
                            />
                          </FormControl>
                          <FormControl
                            sx={{ m: 1, mb: 3, mt: 2, width: "15ch" }}
                            variant="outlined"
                          >
                            <InputLabel htmlFor="amount-input">
                              Amount
                            </InputLabel>
                            <OutlinedInput
                              onChange={changeCategories}
                              name={`${index}-value`}
                              value={cat.value}
                              label={"Amount"}
                              autoFocus={!cat.focusPercent}
                              id="amount-input"
                              endAdornment={"XAF"}
                            />
                          </FormControl>

                          {/* <TextField type="number"
                                            label={"Percent of budget"}
                                            onChange={changeCategories} name={`${index}-percent`}
                                            value={cat.percent} /> */}
                          {/* <TextField
                                            label={"Amount XAF"}
                                            onChange={changeCategories}
                                            type="number"
                                            autoFocus={true}
                                            name={`${index}-value`}
                                            value={cat.value} /> */}
                        </Box>

                        <Button
                          sx={{ display: "block" }}
                          onClick={() => toggleEdit(index, false)}
                          variant="contained"
                          color={"primary"}
                        >
                          Ok
                        </Button>
                      </Box>
                    </CardContent>
                  )}

                  {!cat.edit && (
                    <CardContent>
                      <Typography
                        marginRight={2}
                        variant="caption"
                        color="text.secondary"
                      >
                        {cat.description}
                      </Typography>
                      {/* <Typography variant="caption" color="text.secondary">
                            Percent: {cat.percent}%
                        </Typography> */}
                    </CardContent>
                  )}
                  {!cat.edit && (
                    <CardActions disableSpacing>
                      <IconButton
                        onClick={() => toggleEdit(index, true)}
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="share">
                        {/* <ShareIcon /> */}
                      </IconButton>
                      {cat.infoParagraphs && cat.infoParagraphs?.length > 0 && (
                        <ExpandMore
                          onClick={() => toggleInfo(index, !cat.expanded)}
                          aria-expanded={cat.expanded}
                          aria-label="show more"
                          expand={cat.expanded || false}
                        >
                          {cat.expanded && <ExpandMoreIcon />}
                          {!cat.expanded && <InfoIcon />}
                        </ExpandMore>
                      )}
                    </CardActions>
                  )}
                  <Collapse in={cat.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      {cat.infoParagraphs &&
                        cat.infoParagraphs.map((p) => {
                          return (
                            <>
                              {p.title && (
                                <Typography variant="overline">
                                  {p.title}
                                </Typography>
                              )}
                              <Typography paragraph>{p.text}</Typography>
                            </>
                          );
                        })}
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      {/* <Box>
                <Button variant='outlined' color="primary" sx={{ backgroundColor: "white" }} onClick={() => generateProposal()}>Request Proposal</Button>
            </Box> */}
    </Box>
  );
}
